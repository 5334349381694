var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"id":"booking-list","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 pt-3 mb-15",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","color":"primary","icon":"mdi-calendar-multiple-check"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('booking.title'))+" ")])])]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.booking,"options":_vm.paginationOptions,"hide-default-footer":""},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([{key:"item.carDealer.name",fn:function(ref){
var item = ref.item;
return [(item.carDealer)?_c('span',[_vm._v(_vm._s(item.carDealer.code)+" | "+_vm._s(item.carDealer.name)+" | "+_vm._s(item.carDealer.carDealerRegion))]):_c('span',[_vm._v(_vm._s(_vm.$t('booking.no_car_dealer')))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.trainingSession.startAt))+" ")]}},{key:"item.trainingSession.startAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatHour")(item.trainingSession.startAt))+" ")]}},{key:"item.trainingSession.endAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatHour")(item.trainingSession.endAt))+" ")]}},{key:"item.needTraining",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":!item.needTraining ? 'error'
                  : item.company === item.needTraining.company.name && (item.carModel !== null && item.carModel.model === item.needTraining.carModel.model) ? 'success'
                    : 'orange'}},[_vm._v(" mdi-magnify ")])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"success"}},[_c('v-icon',{on:{"click":function($event){return _vm.goToDetailBooking(item)}}},[_vm._v(" mdi-eye ")])],1)],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }