






















































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { Booking } from '@/api/interfaces/booking'
import { QueryParams } from '@/store/api-plateform-utils'
import {
  usePagination,
  PaginationOptions,
  parseQuery,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import { BookingStatus } from '@/api/enums/bookingStatus'

export default defineComponent({
  setup (props, ctx) {
    const query = {
      ...router.app.$route.query,
    }
    delete query.reference
    const totalItems = () => {
      return ctx.root.$store.state.booking.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
    }
  },
  name: 'BookingList',
  components: {
    Pagination,
  },
  data () {
    return {
      title: this.$t('booking.title'),
      headers: [
        {
          text: this.$t('booking.car_dealer'),
          align: 'start',
          value: 'carDealer.name',
        },
        {
          text: this.$t('booking.date'),
          value: 'date',
        },
        {
          text: this.$t('booking.start_at'),
          value: 'trainingSession.startAt',
        },
        {
          text: this.$t('booking.end_at'),
          value: 'trainingSession.endAt',
        },
        {
          text: this.$t('booking.first_name'),
          value: 'firstName',
        },
        {
          text: this.$t('booking.last_name'),
          value: 'lastName',
        },
        {
          text: this.$t('booking.phone'),
          value: 'phone',
          sortable: false,
        },
        {
          text: this.$t('booking.email'),
          value: 'email',
        },
        {
          text: this.$t('booking.company'),
          value: 'company',
        },
        {
          text: this.$t('booking.found'),
          value: 'needTraining',
        },
        {
          text: this.$t('booking.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('booking', {
      booking: 'list',
      loading: 'loading',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...{ bookingStatus: BookingStatus.CANCELED },
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  created () {
    this.load(this.baseRequest)
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.loadData()
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('booking', {
      load: 'load',
    }),
    loadData () {
      this.load(this.baseRequest)
    },
    goToDetailBooking (item: Booking) {
      if (item.id) {
        this.$router.push({
          name: 'Booking',
          params: { idBooking: item.id.toString() },
        })
      }
    },
  },
})
